// import client from './client'
//
// export const requestPatch = async (url, updateData, config) => {
//
//     try {
//         const response = await client.patch(url, updateData, config)
//         return response.data
//     } catch (error) {
//         if (error.response.data) {
//             return error.response.data
//         } else {
//             throw error
//         }
//     }
// }
//
// export const requestDelete = async (url, config) => {
//     try {
//         const response = await client.delete(url, config)
//         return response.data
//     } catch (error) {
//         if (error.response.data) {
//             return error.response.data
//         } else {
//             throw error
//         }
//     }
// }
//
// export const requestFile = async (url, formData, config = {}) => {
//     try {
//         const response = await client.post(url, formData, {
//             ...config,
//             headers: {
//                 'Content-Type': 'multipart/form-data',
//             },
//         })
//         return response.data
//     } catch (error) {
//         if (error.response.data) {
//             return error.response.data
//         } else {
//             throw error
//         }
//     }
// }
//
// export const requestGet = async (url, config) => {
//     try {
//         const response = await client.get(url, config)
//         return response.data
//     } catch (error) {
//
//
//
//         /*  추후에 수정할 것 - 공통모듈에 있으면 안되는 코드  */
//         // expired token 시 return.
//         if( ( error.response.status === 500 || error.response.status === 401) && error.response.data.path === '/api/user/me' ){
//             localStorage.clear();
//             window.location.href="/";
//         }
//
//         if (error.response.data) {
//             return error.response.data
//         }
//         else {
//             throw error
//         }
//     }
// }
//
// export const requestPut = async (url, body, config) => {
//     try {
//
//         const response = await client.put(url, body, config)
//         return response.data
//     } catch (error) {
//         if (error.response.data) {
//             return error.response.data
//         } else {
//             throw error
//         }
//     }
// }
//
// export const requestPost = async (url, body, config) => {
//     try {
//         const response = await client.post(url, body, config)
//         return response.data
//     } catch (error) {
//         if (error.response.data) {
//             return error.response.data
//         } else {
//             throw error
//         }
//     }
// }
//
//


import {client} from './client'
import {refresh} from "./refresh";
import {removeCookie, setCookie} from "./loginCookie";


export const cookieSetting = (autoLoginCheck, code) => {
    if (autoLoginCheck === 'y') {
        setCookie('pid_ys', code, {
            path: '/',
            maxAge: 60 * 60 * 10

            // 아래는 라이브서버에서
            // maxAge: 60 * 60 * 24 * 15
        })
    } else {
        setCookie('pid_ys', code, {
            path: '/',
            maxAge: 60 * 60 * 10

            // 아래는 라이브서버에서
            // maxAge: 60 * 60 * 24
        })
    }
}

export const requestPatch = async (url, updateData, config) => {
    try {
        const response = await client.patch(url, updateData, config)
        return response.data
    } catch (error) {
        if (error.response.data) {
            return error.response.data
        } else {
            throw error
        }
    }
}


export const requestDelete = async (url, config) => {
    try {
        const response = await client.delete(url, config)
        return response.data
    } catch (error) {
        if (error.response.data) {
            return error.response.data
        } else {
            throw error
        }
    }
}


export const requestFile = async (url, formData, config = {}) => {
    try {
        const response = await client.post(url, formData, {
            ...config,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        return response.data
    } catch (error) {
        if (error.response.data) {
            return error.response.data
        } else {
            throw error
        }
    }
}


export const requestGet = async (url, config) => {

    try {
        const response = await client.get(url, config)
        return response.data;

    } catch (error) {
        // console.log(error)
        // console.log(error?.response?.data?.code)


        if (error?.response?.data?.code === 1002) {
            // removeCookie('wid_ys')
            if (localStorage.getItem('autoLogin')) {
                cookieSetting(localStorage.getItem('autoLogin'), 1002)
                localStorage.removeItem('autoLogin');

            }
            window.location.href = "/login";
        } else if (error?.response?.data?.code === 1003) {
            if (localStorage.getItem('autoLogin')) {
                cookieSetting(localStorage.getItem('autoLogin'), 1003)
                localStorage.removeItem('autoLogin');
            }
            window.location.href = "/login";
        } else if (error?.response?.data?.code === 1013) {
            return await refresh();
        } else if (error?.response?.data?.code === 1014) {
            if (localStorage.getItem('autoLogin')) {
                removeCookie('pid_ys')
                localStorage.removeItem('autoLogin');
            }
            window.location.href = "/login";
        } else if (error?.response?.data?.code === 1015) {
            if (localStorage.getItem('autoLogin')) {
                removeCookie('pid_ys')
                localStorage.removeItem('autoLogin');
            }
            window.location.href = "/login";
        } else if (error?.response?.data) {
            return error?.response?.data
        } else {
            throw error
        }
    }
}


export const requestPut = async (url, body, config) => {
    try {

        const response = await client.put(url, body, config)
        return response.data
    } catch (error) {
        if (error.response.data) {
            return error.response.data
        } else {
            throw error
        }
    }
}


export const requestPost = async (url, body, config) => {
    try {
        const response = await client.post(url, body, config)
        return response.data
    } catch (error) {
        // console.log(error)
        if (error?.response?.data?.code === 1002) {
            // removeCookie('wid_ys')
            if (localStorage.getItem('autoLogin')) {
                cookieSetting(localStorage.getItem('autoLogin'), 1002)
                localStorage.removeItem('autoLogin');

            }
            window.location.href = "/login";
        } else if (error?.response?.data?.code === 1003) {

            // removeCookie('wid_ys')
            if (localStorage.getItem('autoLogin')) {
                cookieSetting(localStorage.getItem('autoLogin'), 1003)
                localStorage.removeItem('autoLogin');

            }
            window.location.href = "/login";
        } else if (error?.response?.data?.code === 1013) {
            return await refresh()
        } else if (error?.response?.data?.code === 1014) {
            if (localStorage.getItem('autoLogin')) {
                removeCookie('pid_ys')
                localStorage.removeItem('autoLogin');
            }
            window.location.href = "/login";
        } else if (error?.response?.data?.code === 1015) {
            if (localStorage.getItem('autoLogin')) {
                removeCookie('pid_ys')
                localStorage.removeItem('autoLogin');
            }
            window.location.href = "/login";
        } else if (error?.response?.data) {
            return error?.response?.data
        } else {
            throw error
        }
    }
}
