import {client} from "./client";
import {removeCookie, setCookie} from "./loginCookie";

const cookieSetting = (autoLoginCheck,code)=>{
    if(autoLoginCheck === 'y'){
        setCookie('pid_ys',code,{
            path:'/',

            maxAge: 60 * 60 * 10
            // maxAge: 60 * 60 * 24 * 15


        })
    }else{
        setCookie('pid_ys',code,{
            path:'/',

            maxAge: 60 * 60 * 10
            // maxAge: 60 * 60 * 24

        })
    }
}



const refresh = async (config) => {
        const autoState = localStorage.getItem('autoLogin')

        //액세스 토큰 재발급 요청
        await client.post(`/public/auth/refresh?isAuto=${autoState}`)
            .then((res) => {

                if (res.data.code === 0) {
                    if (localStorage.getItem('autoLogin')) {
                        cookieSetting(localStorage.getItem('autoLogin'),0)
                    }
                    window.location.reload();
                }
            })
            .catch((err) => {
                if (err.response.data.code === 1002) {
                    // removeCookie('wid_ys')
                    if (localStorage.getItem('autoLogin')) {
                        cookieSetting(localStorage.getItem('autoLogin'),1002)
                        localStorage.removeItem('autoLogin');
                    }

                    window.location.href = '/login';
                }else if(err.response.data.code === 1003){
                    // removeCookie('wid_ys')
                    if (localStorage.getItem('autoLogin')) {
                        cookieSetting(localStorage.getItem('autoLogin'),1003)
                        localStorage.removeItem('autoLogin');

                    }
                    window.location.href = '/login';
                }
            })
        ;
        return config;
    }
;


export {refresh};